.color-services{
    background-color: white;
    padding-top: 75px;
}

.colors-headers{
    font-family: 'NunitoSans-Bold';
    font-size: clamp(1.5rem, 1.3667rem + 0.7111vw, 2.5rem);
  text-align: center;
  margin-bottom: 62px;

}

.loading-circle {
    width: 40px;
    height: 40px;
    border: 4px solid transparent;
    border-top: 4px solid rgb(235, 225, 196);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loading-screen{
    z-index: 99999;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgb(6, 25, 18);
    transition: right 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

 .loading-paragraph{
    color: rgb(235, 225, 196);
    font-family: 'NunitoSans-Light';
    margin-top: 20px;
    align-self: center;
    font-size: 20px;

 }

 .close-loading{
    display: none;
 }

 .pulsing-logo{
  width: 30%;
  margin: 0 auto;
  text-align: center;
 }

 .nobglogoclass{
  width: 100%;
 }

 @keyframes pulse3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse3 {
  animation: pulse3 2s infinite;
}

@keyframes dot-pulse {
  0%, 60%, 100% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
}

.dot1{
  text-align: center;
  animation: dot-pulse 2s infinite;
  animation-delay: 0s; /* Delay the start of the animation for the second dot */

}

.dot2 {
  text-align: center;
  animation: dot-pulse 2s infinite;
  animation-delay: 0.5s; /* Delay the start of the animation for the second dot */
}

.dot3 {
  text-align: center;
  animation: dot-pulse 2s infinite;
  animation-delay: 1s; /* Delay the start of the animation for the third dot */
}



@media (max-width: 1000px) {
    .color-services{
        background-color: white;
        padding-top: 75px;
        padding-bottom: 0;
    }
}