.tree-removal-main{
    overflow: hidden;
}

.service-layout{
    background-color: white;
    width: 100%;
    margin-top: 75px;
}

.service-layout-row{
    display: flex;
}

.servicer-right{
    width: 50%;
    display: flex;
    
}

.servicer-left{
    width: 50%;
    display: flex;
    
}

@media (max-width: 1000px) {
    .service-layout-row{
        flex-direction: column;
    }

    .servicer-right{
        width: 100%;
        display: flex;
        
    }
    
    .servicer-left{
        width: 100%;
        display: flex;
        
    }
}